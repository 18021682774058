.contact {
  background: var(--clr-accent-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0.125rem solid var(--clr-basic-800);
  box-shadow: 6px 6px 0px 0px var(--clr-basic-800);
  text-align: start;
  padding: 0rem;
  position: relative;
}

.contact .contact-content {
  padding: 2rem;
}

.contact .contact-content p a {
  text-decoration: underline;
}

.contact .contact-content img {
  display: block;
  margin: auto;
  width: 100%;
}

.contact .contact-content h1 {
  font-family: var(--ff-primary-heading);
  font-size: var(--fs-primary-heading);
}

.contact .contact-content .claim {
  font-family: var(--ff-secondary);
  font-size: var(--fs-claim);
  line-height: 5.5rem;
  padding-bottom: var(--size-0150);
}

.color-cards {
  transform: translateY(-79.5%);
  position: absolute;
  z-index: -1;
}

.card {
  border-radius: 0;
  border: 0;
  width: 100vw;
  height: 4rem;
}

.orange {
  background: var(--clr-accent-orange);
}

.coral {
  background: var(--clr-accent-coral);
}

.green {
  background: var(--clr-accent-green);
}

.big {
  background: var(--clr-accent-orange);
  height: 15rem;
}

@media screen and (max-width: 900px) {
  .contact {
    display: grid;
    gap: 2rem;
    width: 92vw;
  }

  .contact .contact-content {
    align-items: start;
    width: 95%;
  }

  .contact img {
    justify-self: end;
  }

  .contact .contact-content h1 {
    font-size: var(--fs-700);
  }

  .contact .contact-content .claim {
    font-size: var(--fs-850);
    line-height: 4rem;
  }

  .color-cards {
    transform: translateY(-83.5%);
  }

  .big {
    height: 22rem;
  }
}

