.scroll {
  background: var(--clr-basic-900);
  width: 100vw;
  height: 6rem;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
}

.scroll .loop-texts {
  color: var(--clr-basic-100);
  font-size: var(--fs-700);
  display: flex;
  align-items: center;
  gap: var(--size-03);
  white-space: nowrap;
  animation: loopTexts 18s infinite linear;
  position: absolute;
  top: 25%;
}

.scroll .loop-texts p {
  font-weight: var(--fw-medium);
}

@keyframes loopTexts {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
