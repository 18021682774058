footer {
  border-block: 2px solid var(--clr-basic-800);
  margin-top: 5.5rem;
  margin-bottom: 0rem;
  padding: 0.5rem;
  width: 100vw;
}

footer .footer-content {
  max-width: min(97.125em - var(--size-02)); /*  1554px */
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
}

footer .footer-content .logo {
  font-family: var(--ff-secondary);
  font-size: 1.5625rem;
  font-weight: medium;
  line-height: 1.6875rem; /* 108% */
}

@media screen and (max-width: 500px) {
  footer {
    margin-bottom: 0;
  }
  footer .footer-content {
    display: grid;
    padding: 2rem 1.5rem;
    margin-top: 0rem;
  }

  footer .footer-content .logo {
    margin-bottom: 1.5rem;
  }
}
