.skill {
  text-align: start;
}

.skill .skill-content {
  min-width: 50%;
  width: 48.75rem;
  max-width: 50vw;
}

.skill .skill-content h1 {
  font-size: var(--fs-primary-heading);
  font-family: var(--ff-secondary);
  margin-bottom: var(--size-02);
}

.skill .skill-content h2 {
  font-size: var(--fs-secondary-heading);
  margin-bottom: var(--size-01);
}

.skill .skill-content p {
  font-size: var(--fs-500);
  line-height: var(--size-02);
}

.skill .icon-container {
  display: flex;
  gap: var(--size-01);
  margin-top: var(--size-0250);
}

.skill .icon-container .icon {
  width: 8rem;
  aspect-ratio: 1;
  border: 0.125rem solid var(--clr-basic-800);
  padding: var(--size-01);
  box-shadow: 6px 6px 0px 0px var(--clr-basic-800);
  margin-block: var(--size-04);
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill .icon-container .icon img {
  width: 70%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

@media screen and (max-width: 990px) {
  .skill .icon-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-01);
  }

  .skill .skill-content {
    min-width: initial;
    width: 100%;
    max-width: initial;
  }

  .skill .icon-container .icon {
    flex-shrink: 2;
    margin-block: var(--size-mid);
  }
}
