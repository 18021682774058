header {
  background: var(--clr-accent-yellow);
  width: 100vw;
  border-bottom: 0.125rem solid var(--clr-basic-800);
  position: fixed;
  z-index: 1000;
}

nav {
  max-width: min(97.125em - var(--size-02));

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  margin-inline: auto;
  padding: 0.7rem 2rem;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 5rem;
  padding: 2rem 2rem;
  border-left: 0.125rem solid var(--clr-basic-800);
}

nav .menu i {
  font-size: 2rem;
}

nav .logo {
  font-family: var(--ff-secondary);
  font-size: var(--fs-600);
  font-weight: var(--fw-medium);
  line-height: 1.6875rem; /* 108% */
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 0rem;
}

nav ul li {
  color: var(--clr-basic-800);
  line-height: 1.5rem; /* 150% */
  height: 100%;
}

nav ul li a {
  align-items: center;
  padding: rem;
  padding-inline: 2.5rem;
  padding-block: 1.75rem;
  margin: 0;
  height: 100%;
  border-right: 0.125rem solid var(--clr-basic-800);
  transition: all 0.1s ease-in;
}

nav ul a:hover {
  background: var(--clr-primary-50);
}

nav ul a:active {
  background: var(--clr-primary-100);
}

nav ul #contactBtn {
  background: var(--clr-primary-400);
}

nav ul #contactBtn:hover {
  background: var(--clr-primary-500);
}

nav ul #contactBtn:active {
  background: var(--clr-primary-600);
}

@media screen and (max-width: 860px) {
  nav {
    flex-direction: column;
    align-items: start;
    margin: 0.55rem 0rem;
  }

  nav .menu {
    display: flex;
  }

  nav ul {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.3rem;
    margin-bottom: 1rem;

    border-top: 0.125rem solid #212429;
  }

  nav ul.open {
    display: flex;
    transform: translateY(0); /* Show the menu when it's open */
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    border-right: none;
    padding-inline: 2.5rem;
    padding-block: 1rem;
    width: 100%;
    display: block;
  }
}
