.hero {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-inline: auto;
  margin-block: 0;
  padding-top: 4rem;
  padding-bottom: 0;
  background-color: var(--clr-accent-yellow);
}

.hero .hero-text {
  padding-block: rem;
  text-align: start;
  min-width: 50%;
  max-width: 40rem;
  margin-inline: auto;
}

.hero .hero-text h2 {
  font-size: var(--fs-700);
  font-weight: var(--fw-medium);
  margin-bottom: var(--size-mid);
}

.hero .hero-text .claim {
  font-family: var(--ff-claim);
  font-size: var(--fs-claim);
  font-weight: var(--fw-medium);
  line-height: 5rem;
  margin-bottom: var(--size-01);
}

.hero .hero-text p {
  margin-bottom: var(--size-04);
}

.hero .hero-text .btnAbout {
  border: 0.125rem solid var(--clr-basic-800);
  background: var(--clr-primary-400);
  padding: var(--padding-btn);
  box-shadow: 6px 6px 0px 0px var(--clr-basic-800);
  transition: all 0.3s ease-in;
}

.hero .hero-text .btnAbout:hover {
  background: var(--clr-primary-500);
}

.hero .hero-text .btnAbout:active {
  background: var(--clr-primary-600);
}

.hero .image {
  display: flex;
  width: 100%;
  height: 35rem;
  border-left: 2px solid var(--clr-basic-800);
  padding-left: 1rem;
  padding-bottom: 0rem;
}

.hero .image img {
  border: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
}

@media screen and (max-width: 860px) {
  .hero {
    display: grid;
    gap: 0;
  }

  .hero .hero-text {
    padding-block: 4rem;
    padding-inline: 1rem;
    text-align: start;
    min-width: 50%;
    max-width: 40rem;
    margin-inline: auto;
  }

  .hero .hero-text .btnAbout {
    display: block;
    text-align: center;
    border: 0.125rem solid var(--clr-basic-800);
    background: var(--clr-primary-400);
    padding: var(--padding-btn);
    box-shadow: 6px 6px 0px 0px var(--clr-basic-800);
    transition: all 0.3s ease-in;
  }

  .hero .image {
    background: var(--clr-accent-green);
    width: 100vw;
    height: auto;
    border-left: none;
    border-top: 2px solid var(--clr-basic-800);
  }
}
