/* Box sizing rules */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html {
  font-size: 62.5%;
} */

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul {
  list-style: none;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: "IBM Plex Sans", "Syne", sans-serif;
  background-color: var(--clr-accent-yellow);
  color: var(--clr-basic-900);
  text-align: center;
  overflow-x: hidden;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--clr-basic-900);
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

:root {
  /* <-- primary-color --> */
  --clr-primary-50: hsl(44, 74%, 91%); /* <-- Menu-Hover */
  --clr-primary-100: hsl(45, 73%, 87%); /* <-- Menu-pressed */
  --clr-primary-200: hsl(45, 74%, 88%); /* <-- Secondary-Hover */
  --clr-primary-300: hsl(44, 74%, 79%); /* <-- Secondary-Pressed */
  --clr-primary-400: hsl(45, 92%, 62%); /* <-- Default */
  --clr-primary-500: hsl(45, 94%, 49%); /* <-- Primary-Hover*/
  --clr-primary-600: hsl(45, 94%, 45%); /* <-- Primary-Pressed */

  /* <-- accent-color --> */

  --clr-accent-beige: hsl(26, 37%, 96%); /* <-- Baige bg color */
  --clr-accent-yellow: hsl(45, 73%, 96%); /* <-- Yellow bg color*/
  --clr-accent-orange: hsla(45, 92%, 62%, 1);
  --clr-accent-green: hsl(175, 50%, 64%);
  --clr-accent-purple: hsl(224, 64%, 82%);
  --clr-accent-pink: hsl(313, 100%, 78%);
  --clr-accent-coral: hsl(6, 100%, 73%);

  /* <-- basic-color --> */
  --clr-basic-100: hsl(0, 0%, 100%);
  --clr-basic-800: hsl(218, 11%, 15%);
  --clr-basic-900: hsl(0, 0%, 0%);

  /* FONT FAMILY */
  /* <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&family=Syne&display=swap" rel="stylesheet"> */
  --ff-primary: "IBM Plex Sans";
  --ff-secondary: "Syne";

  --ff-body: var(--ff-primary);
  --ff-primary-heading: var(--ff-secondary);
  --ff-secondary-heading: var(--ff-primary);
  --ff-claim: var(--ff-secondary);

  /* FONT WEIGHT */
  --fw-regular: 400;
  --fw-medium: 500;

  /* FONT SIZE */
  --fs-400: 1rem; /* Default*/
  --fs-500: 1.25rem; /* 20 */
  --fs-600: 1.5rem; /* 24 Mobile*/
  --fs-700: 2rem; /* 32 */
  --fs-750: 2.5rem; /* 40 Mobile*/
  --fs-800: 3rem; /* 48 */
  --fs-850: 3.5rem; /* 56 Mobile*/
  --fs-900: 5rem; /* 80 */

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-claim: var(--fs-900);
  --fs-nav: var(--fs-400);
  --fs-button: var(--fs-400);

  /* OTHER SIZE */
  --size-mid: 0.5rem;
  --size-01: 1rem;
  --size-0125: 1.25rem;
  --size-0150: 1.5rem;
  --size-02: 2rem;
  --size-0250: 2.5rem;
  --size-03: 3rem;
  --size-0350: 3.5rem;
  --size-04: 4rem;
  --size-0450: 4.5rem;
  --size-05: 5rem;
  --size-0550: 5.5rem;
  --size-06: 6rem;
  --size-0650: 6.5rem;
  --size-07: 7rem;
  --size-0750: 7.5rem;

  --padding-btn: 1.125rem 2.5rem;
  --padding-block: ;
  --padding-inline: 2rem;

  --margin-block: ;
  --margin-inline: 10.5rem;
}

section {
  margin-inline: auto;
  padding: 5rem 2rem;
  max-width: min(97.125em - var(--size-02)); /*  1554px */
}

@media screen and (max-width: 53em) {
  :root {
    --fs-body: var(--fs-500);
    --fs-primary-heading: var(--fs-750);
    --fs-secondary-heading: var(--fs-600);
    --fs-claim: var(var(--fs-850));
  }
}
